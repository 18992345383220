import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { VARIABLES } from "../Constant";
import { TypeMasterContext } from "../Context/TypeMaster";
import Swal from "sweetalert2";
import { UserDetailsContext } from "../Context/UserDetailsContext";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

const SignUp = (props) => {
  const { getUserDetailsData,setActiveTab,setUserDetails } = useContext(UserDetailsContext);
  const { role } = useContext(TypeMasterContext);

  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const [confirmPassToggle, setConfirmPassToggle] = useState(false);

  const handleModalClose = () => {
    reset();
  };

  const onSubmit = (data) => {
    console.log(data);
    const role_type = role.find((api) => api.label === "Candidate");
    console.log(role_type);
    let user = new FormData();
    user.append("first_name", data.first_name);
    user.append("last_name", data.last_name);
    user.append("email", data.email);
    user.append("mobile", data.mobile_number);
    user.append("password", data.password);
    user.append("confirm_password", data.confirm_pwd);
    user.append("role", 37);
    user.append("portal_unique_id", VARIABLES.career_login);

    console.log(user);
    for (var pair of user.entries()) {
      console.log(pair);
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api/career-signup/",
      data: user,
    };

    setIsLoading(true);
    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const status = response.status;
        if (status === 201 && response.data.data.length > 0) {
          setIsLoading(false);
          Swal.fire({
            text: "Your Account is created successfully! Sign in to continue",
            showDenyButton: true,
            confirmButtonText: "Sign in",
            denyButtonText: `Cancel`,
          }).then((result) => {
            if (result.isConfirmed) {
              // document.getElementById("button-button-signinmodal").click();
              onLoginHandler(data)
            } else {
              handleModalClose();
            }
          });
          reset();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error?.response?.data?.message || "Something went wrong!",
        });
        setIsLoading(false);
      });
  };

  const onLoginHandler = (data) => {
    let user = new FormData();
    user.append("email", data.email);
    user.append("password", data.password);
    user.append("portal_unique_id", VARIABLES.career_login);

    const url = VARIABLES.url + "/api/login-career-page/";
    console.log(url);

    let reqOptions = {
      url: url,
      method: "post",
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setIsLoading(true);
    axios
      .request(reqOptions)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const loginStatus = response.status;
        console.log("loginStatus", loginStatus);
        if (loginStatus === 200) {
          const userData = response.data.data[0].user_details;
          setUserDetails(userData);
          secureLocalStorage.setItem("user-details", userData);
          console.log("userData", userData);
          setIsLoading(false);
          getUserDetailsData(userData?.user?.id);
          onSubmitCandidateHandler(userData?.user?.id, userData);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        if (error.response?.status === 401) {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: error?.response?.data?.message || "Something went wrong!",
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: error?.response?.data?.message || "Something went wrong!",
          });
        }
      });
    handleModalClose();
  };

  const onSubmitCandidateHandler = (user_id, userData) => {
    const job_data = secureLocalStorage.getItem("job_opening_data");
    console.log(job_data);
    var candidate_info = JSON.stringify({
      job_opening_id: job_data?.id,
      user_id: user_id,
      notice_period: null,
      current_previous_company: "",
      expected_salary: null,
      experience: null,
      is_draft: true,
      created_by_id: user_id,
      updated_by_id: user_id,
    });

    console.log(candidate_info);

    const url = VARIABLES.url + "/api/candidatejob/";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: candidate_info,
    };
    console.log(candidate_info);
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsLoading(false);
          console.log(JSON.stringify(response.data.data));
          secureLocalStorage.setItem(
            "candidate_info",
            response.data.data.candidate_data?.id
          );
          setSubmitMessage({
            type: "success",
            icon: "check",
            message:
              "Thankyou for choosing the Job, Please complete your Process to apply for the Job",
          });
          props.loginHandler(userData);
          document.body.style.overflow = "auto";
          const modalBackdrop = document.querySelector(".modal-backdrop");
          if (modalBackdrop) {
            modalBackdrop.remove();
          }
          console.log("In");
          setActiveTab(1)
          navigate(`/apply/${job_data?.url_required}`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to apply for Job. Please check your network",
        });
      });
  };

  const validatePasswordMatch = (value) => {
    console.log(value);
    const password = watch("password");
    console.log(password);
    return value === password || "Passwords does not match";
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal fade"
        id="signup"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 class="modal-title">Sign-Up to your career account</h4>
              <button
                type="button"
                className="btn-close"
                id="button-button-closesignup"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClose}
              ></button>
            </div>
            <div className="brand-logo d-flex justify-content-center">
              <a
                href="https://careers.fluidscapes.in/"
                target="_blank"
                className="logo-link"
                id="button-button-logo"
              >
                <img
                  className="logo-light logo-img logo-img-xl"
                  src="./assets/images/fsc_animated_logo_black.gif"
                  srcSet="./assets/images/fsc_animated_logo_black.gif"
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img logo-img-xl"
                  src="./assets/images/fsc_animated_logo_black.gif"
                  srcSet="./assets/images/fsc_animated_logo_black.gif"
                  alt="logo-dark"
                />
              </a>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("first_name", { required: true })}
                        className="form-control"
                        id="text-form-first_name"
                        type="text"
                        placeholder="Enter Your First name"
                      />
                      {errors.first_name && (
                        <span className="fs-8 text-danger">
                          First Name is required
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Middle Name</label>
                      <input
                        {...register("middle_name")}
                        className="form-control"
                        id="text-form-middle_name"
                        type="text"
                        placeholder="Enter Your middle name"
                      />
                    </div>
                  </div> */}

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("last_name", { required: true })}
                        className="form-control"
                        id="text-form-last_name"
                        type="text"
                        placeholder="Enter your last name"
                      />
                      {errors.last_name && (
                        <span className="fs-8 text-danger">
                          Last Name is required
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("email", { required: true })}
                        className="form-control"
                        id="email-form-email"
                        type="email"
                        placeholder="Enter email here..."
                      />
                      {errors.email && (
                        <span className="fs-8 text-danger">
                          Email address is required
                        </span>
                      )}
                    </div>
                  </div>

                  {/* <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Username <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("username", {
                          required: true,
                          pattern: /^[^\s]+$/,
                        })}
                        className="form-control"
                        id="text-form-username"
                        type="text"
                        placeholder="Enter Username here..."
                      />
                      {errors.username &&
                        errors.username.type === "required" && (
                          <span className="fs-8 text-danger">
                            Username is required
                          </span>
                        )}
                      {errors.username &&
                        errors.username.type === "pattern" && (
                          <span className="fs-8 text-danger">
                            Username should not contain spaces
                          </span>
                        )}
                    </div>
                  </div> */}

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Mobile Number <span className="text-danger">*</span>
                      </label>
                      <input
                        {...register("mobile_number", {
                          required: true,
                          maxLength: 10,
                          minLength: 10,
                        })}
                        className="form-control"
                        id="number-form-mobile_number"
                        type="number"
                        placeholder="Enter your mobile number"
                      />
                      {errors.mobile_number &&
                        errors.mobile_number.type === "required" && (
                          <span className="fs-8 text-danger">
                            Mobile number is required
                          </span>
                        )}
                      {errors.mobile_number &&
                        errors.mobile_number.type === "minLength" && (
                          <span className="fs-8 text-danger">
                            Mobile number should be at least 10 digits
                          </span>
                        )}
                      {errors.mobile_number &&
                        errors.mobile_number.type === "maxLength" && (
                          <span className="fs-8 text-danger">
                            Mobile number should not exceed 10 digits
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          id="password-form-password"
                          placeholder="Enter Password"
                          {...register("password", { required: true })}
                        />

                        {/* <input
                          type={`${
                            passwordToggle === true ? "text" : "password"
                          }`}
                          className="form-control"
                          id="password-form-password"
                          placeholder="Enter Password"
                          {...register("password", { required: true })}
                        /> */}
                        {/* <span
                          className="input-group-text"
                          onClick={() => setPasswordToggle(!passwordToggle)}
                          id="basic-addon2"
                        >
                          <i
                            className={`${
                              passwordToggle === true
                                ? "icon ni ni-eye"
                                : "icon ni ni-eye-off"
                            }`}
                          ></i>
                        </span> */}
                      </div>
                      {errors.password && (
                        <span className="fs-8 text-danger">
                          Password is required
                        </span>
                      )}
                      {watch("password") && watch("password").length < 8 && (
                        <span className="fs-8 text-danger">
                          Password must be at least 8 characters
                        </span>
                      )}
                      {watch("password") &&
                        watch("password").length > 8 &&
                        /^\d+$/.test(watch("password")) && (
                          <span className="fs-8 text-danger">
                            Password must be alpha-numeric
                          </span>
                        )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type={`${
                            confirmPassToggle === true ? "text" : "password"
                          }`}
                          className="form-control"
                          id="password-form-confirm_pwd"
                          placeholder="Enter Password"
                          {...register("confirm_pwd", {
                            required: true,
                            validate: validatePasswordMatch,
                          })}
                        />
                        <span
                          className="input-group-text"
                          onClick={() =>
                            setConfirmPassToggle(!confirmPassToggle)
                          }
                          id="basic-addon2"
                        >
                          <i
                            className={`${
                              confirmPassToggle === true
                                ? "icon ni ni-eye"
                                : "icon ni ni-eye-off"
                            }`}
                          ></i>
                        </span>
                      </div>
                      {console.log(errors.confirm_pwd?.message)}
                      {errors.confirm_pwd?.message && (
                        <span className="fs-8 text-danger">
                          {errors.confirm_pwd.message ||
                            "Confirm Password is required"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-group d-flex justify-content-center">
                    {isLoading === true ? (
                      <div>Loading...</div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary btn-block"
                        id="submit-button-register"
                      >
                        Register
                      </button>
                    )}
                  </div>

                  <div
                    className="form-note-s2 text-center pt-4"
                    style={{ fontSize: "14px" }}
                  >
                    {" "}
                    Already have an account?
                    <a
                      href="javascript:void(0)"
                      data-bs-target="#loginModal"
                      data-bs-toggle="modal"
                      id="button-button-signinmodal"
                    >
                      {" "}
                      Sign in
                    </a>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <div className="nk-block-content text-center">
                <p className="text-soft fs-7">
                  &copy; 2024 Fluidscapes Consultant Pvt.Ltd.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {submitMessage && (
            <div
              className="example-alerts position-fixed bottom-0 end-0 p-3"
              style={{ zIndex: 100 }}
            >
              <div className="gy-4">
                <div className="example-alert">
                  <div
                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                  >
                    <em
                      className={`icon ni ni-${submitMessage.icon}-circle`}
                    ></em>{" "}
                    <strong>{submitMessage.message}</strong>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SignUp;
