import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constant";
import { CandidateExamContext } from "../../../Context/CandidateExamContext";

const MettleTest = () => {
  const { candidateExam, getCandidateExamData } =
    useContext(CandidateExamContext);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");
  console.log(candidateJobID);

  const [submitMessage, setSubmitMessage] = useState(null);

  const handleStatus = (data) => {
    console.log(data);
    var user = JSON.stringify({
      test_done: data === 1 ? "Yes" : "No",
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/candidateexam/${candidateJobID}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(user));
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Successfully submitted",
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to submit",
        });
      });
  };

  useEffect(() => {
    getCandidateExamData();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-history-item">
        <div className="nk-history-symbol">
          <div className="nk-history-symbol-dot"></div>
        </div>
        <div className="nk-history-content">
          <h5>Step 3</h5>
        </div>
      </div>
      <div className="nk-history-item">
        <div className="nk-history-content">
          <div className="card">
            <div className="card-body">
              <h5 className="fs-14px fw-bold">Mettle Test</h5>
              <div className="row g-4">
                <div class="profile-ud-list">
                  <div class="profile-ud-item">
                    <div class="profile-ud wider">
                      <span class="profile-ud-label">Test Name: </span>
                      <span class="profile-ud-value">
                        {candidateExam?.test_name || "-"}
                      </span>
                    </div>
                  </div>
                  <div class="profile-ud-item">
                    <div class="profile-ud wider">
                      <span class="profile-ud-label">Test Link: </span>
                      <span class="profile-ud-value">
                        {candidateExam?.test_link || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-success my-2 me-2"
                onClick={() => handleStatus(1)}
              >
                Yes
              </button>
              <button
                className="btn btn-danger my-2"
                onClick={() => handleStatus(0)}
              >
                No
              </button>
              <div class="profile-ud-list">
                <div class="profile-ud-item">
                  <div class="profile-ud wider">
                    <span class="profile-ud-label">Status: </span>
                    <span
                      class="profile-ud-value fw-bold text-start"
                      style={{
                        color:
                          candidateExam?.selection_status === "Reject"
                            ? "red"
                            : candidateExam?.selection_status === "Accept"
                            ? "green"
                            : "black",
                      }}
                    >
                      {candidateExam?.selection_status || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MettleTest;
