import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import secureLocalStorage from "react-secure-storage";

const EducationalDetails = () => {
  const {
    educationalDetails,
    getEducationalDetails,
    isEducationalDataLoading,
  } = useContext(UserDetailsContext);

  const location = useLocation();
  console.log(location.state.editpost);

  const candidate_job_id = secureLocalStorage.getItem("candidate_info");

  const resume_id = location.state.editpost?.candidate_resume?.id;
  const user_id = location.state.editpost?.user?.id;
  console.log(user_id);
  console.log(resume_id);

  useEffect(() => {
    getEducationalDetails(candidate_job_id);
  }, []);

  return (
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h4 className="nk-block-title">
                  Candidate's Educational Details
                </h4>
              </div>
            </div>
          </div>
          {isEducationalDataLoading ? (
            <div>Candidate's educational details loading...</div>
          ) : (
            <div>
              {educationalDetails == 0 ? (
                <div>No educational details found</div>
              ) : (
                <div class="nk-block">
                  {educationalDetails?.map((edu, index) => (
                    <div class="card" key={index}>
                      <div class="card-aside-wrap">
                        <div class="card-content">
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="profile-ud-list">
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Education Type
                                    </span>
                                    <span class="profile-ud-value">
                                      {edu?.education_type || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Board Name
                                    </span>
                                    <span class="profile-ud-value">
                                      {edu?.board_name || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Passing Year
                                    </span>
                                    <span class="profile-ud-value">
                                      {edu?.passing_year || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Percentage
                                    </span>
                                    <span class="profile-ud-value">
                                      {edu?.percentage || "-"}
                                    </span>
                                  </div>
                                </div>

                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Specialization
                                    </span>
                                    <span class="profile-ud-value">
                                      {edu?.specialization || "-"}
                                    </span>
                                  </div>
                                </div>

                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Marksheet
                                    </span>
                                    <span class="profile-ud-value">
                                      {edu?.marksheet ? (
                                        <a
                                          className="btn btn-primary"
                                          id="button-button-viewresume"
                                          href={edu?.marksheet}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <em
                                            className="icon ni ni-file-pdf"
                                            style={{ color: "white" }}
                                          ></em>
                                        </a>
                                      ) : (
                                        "-"
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Additional Qualification
                                    </span>
                                    <span class="profile-ud-value">
                                      {edu?.additional_qualification || "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* <!-- .profile-ud-list --> */}
                            </div>
                            {/* <!-- .nk-block --> */}
                            <div class="nk-divider divider md"></div>
                            <div class="nk-block">
                              <div class="nk-block-head nk-block-head-sm nk-block-between">
                                <h5 class="title">Comments</h5>
                              </div>
                              {/* <!-- .nk-block-head --> */}
                              <div class="bq-note">
                                <div class="bq-note-item">
                                  <div class="bq-note-text">
                                    <p>{edu?.comments || "-"} </p>
                                  </div>
                                </div>
                                {/* <!-- .bq-note-item --> */}
                              </div>
                              {/* <!-- .bq-note --> */}
                            </div>
                            {/* <!-- .nk-block --> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EducationalDetails;
