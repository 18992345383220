import React from "react";
import { Route, Routes } from "react-router-dom";
import JobOpening from "../Pages/JobOpeningView/JobOpening";
import PageOpening from "../Pages/JobOpeningView/PageOpening";
import ApplyJobOpen from "../Pages/ApplyJobOpening/ApplyJobOpen";
import Dashboard from "../Pages/Dashboard/Dashboard";
import ProfessionalDetails from "../Pages/Dashboard/ProfessionalDetails";
import EducationalDetails from "../Pages/Dashboard/EducationalDetails";
import HRInterview from "../Pages/WorkFlow/HRInterview";
import Assignment from "../Pages/WorkFlow/Assignment";
import MettleTest from "../Pages/WorkFlow/MettleTest";
import MDApproval from "../Pages/WorkFlow/MDApproval";
import OfferAttach from "../Pages/WorkFlow/OfferAttach";
import Workflow from "../Pages/WorkFlow/Workflow";
import StepperForm from "../Pages/WorkflowStepperForm/StepperForm";
import CandidateOtherDetails from "../Pages/Dashboard/CandidateOtherDetails";

const Content = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route
          path={`/:title`}
          element={<PageOpening loginHandler={props.loginHandler} />}
        />

        <Route path={`/apply/:title`} element={<ApplyJobOpen />} />

        <Route path={`/jobopening`} element={<JobOpening />} />

        <Route path={`/educationaldetails`} element={<EducationalDetails />} />

        <Route
          path={`/professionaldetails`}
          element={<ProfessionalDetails />}
        />

        <Route
          path={`/candidateotherdetails`}
          element={<CandidateOtherDetails />}
        />

        {/* STEPPER */}
        <Route path="/stepper" element={<StepperForm />} />

        {/* WORKFLOW */}
        <Route path={`/hrinterview`} element={<HRInterview />} />
        <Route path={`/assignment`} element={<Assignment />} />
        <Route path={`/mettletest`} element={<MettleTest />} />
        <Route path={`/mdapproval`} element={<MDApproval />} />
        <Route path={`/offerattach`} element={<OfferAttach />} />
        <Route path={`/workflow`} element={<Workflow />} />
      </Routes>
    </>
  );
};

export default Content;
