import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";

export const JobOpeningContext = createContext(null);

function JobOpeningProvider({ children }) {
  const [rawJobPosting, setRawJobPosting] = useState([]);
  console.log(rawJobPosting);

  const [isLoading, setIsLoading] = useState(true);

  const getJobPostingData = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(VARIABLES.url + `/api/jobopening/?platform=career`);
      setRawJobPosting(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJobPostingData();
  }, []);

  return (
    <div>
      <JobOpeningContext.Provider value={{ rawJobPosting, isLoading }}>
        {children}
      </JobOpeningContext.Provider>
    </div>
  );
}

export default JobOpeningProvider;
