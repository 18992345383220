import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import JobOpeningProvider from "./components/Context/JobOpeningContext";
import TypeMasterProvider from "./components/Context/TypeMaster";
import DashboardProvider from "./components/Context/DashboardContext";
import UserDetailsProvider from "./components/Context/UserDetailsContext";
import BackgroundVerificationContextProvider from "./components/Context/BackgroundVerificationContext";
import CandidateDetailsProvider from "./components/Context/CandidateDetailsContext";
import GenericDropdownContextProvider from "./components/Context/GenericDropdownContext";
import InterviewQuestionContextProvider from "./components/Context/InterviewQuestionMasterContext";
import JobAssignmentProvider from "./components/Context/JobAssignment";
import MDApprovalProvider from "./components/Context/MDApprovalContext";
import KYCContextProvider from "./components/Context/KYCContext";
import RefreshTokenProvider from "./components/Context/RefreshTokenContext";
import SendOfferContextProvider from "./components/Context/SendOfferContext";
import SnackbarContextProvider from "./components/Context/SnackbarContext";
import StepperCandidateProvider from "./components/Context/StepperCandidate";
import WorkflowProvider from "./components/Context/WorkflowContext";
import SequenceContextProvider from "./components/DashBoardBase/Pages/WorkflowStepperForm/SequenceContextProvider";
import CandidateEntriesProvider from "./components/Context/CandidateEntriesContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <HelmetProvider>
      <BrowserRouter>
        <JobOpeningProvider>
          <UserDetailsProvider>
            <TypeMasterProvider>
              <DashboardProvider>
                <RefreshTokenProvider>
                  <JobOpeningProvider>
                    <CandidateDetailsProvider>
                      <UserDetailsProvider>
                        <InterviewQuestionContextProvider>
                          <MDApprovalProvider>
                            <JobAssignmentProvider>
                              <SendOfferContextProvider>
                                <WorkflowProvider>
                                  <SequenceContextProvider>
                                    <StepperCandidateProvider>
                                      <BackgroundVerificationContextProvider>
                                        <GenericDropdownContextProvider>
                                          <KYCContextProvider>
                                            <SnackbarContextProvider>
                                              <CandidateEntriesProvider>
                                                <App />
                                              </CandidateEntriesProvider>
                                            </SnackbarContextProvider>
                                          </KYCContextProvider>
                                        </GenericDropdownContextProvider>
                                      </BackgroundVerificationContextProvider>
                                    </StepperCandidateProvider>
                                  </SequenceContextProvider>
                                </WorkflowProvider>
                              </SendOfferContextProvider>
                            </JobAssignmentProvider>
                          </MDApprovalProvider>
                        </InterviewQuestionContextProvider>
                      </UserDetailsProvider>
                    </CandidateDetailsProvider>
                  </JobOpeningProvider>
                </RefreshTokenProvider>
              </DashboardProvider>
            </TypeMasterProvider>
          </UserDetailsProvider>
        </JobOpeningProvider>
      </BrowserRouter>
    </HelmetProvider>
  </>
);
