import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const CandidateInterviewContext = createContext(null);

function CandidateInterviewProvider({ children }) {
  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");
  console.log(candidateJobID);
  const [candidateInterview, setCandidateInterview] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState([]);

  const getCandidateInterviewData = async () => {
    setDashboardLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api/candidateinterview/?candidate_job=${candidateJobID}`
      );
      setCandidateInterview(res.data.data);
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <CandidateInterviewContext.Provider
        value={{
          candidateInterview,
          dashboardLoading,
          getCandidateInterviewData,
        }}
      >
        {children}
      </CandidateInterviewContext.Provider>
    </div>
  );
}

export default CandidateInterviewProvider;
