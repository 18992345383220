import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const CandidateJobContext = createContext(null);

function CandidateJobProvider({ children }) {
  const [candidateJob, setCandidateJob] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState([]);
  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");
  console.log(candidateJobID);

  const getCandidateJobData = async () => {
    setDashboardLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/candidatejob/?candidate_job=${candidateJobID}`
      ); //CHECK API
      setCandidateJob(res.data);
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <CandidateJobContext.Provider
        value={{ candidateJob, dashboardLoading, getCandidateJobData }}
      >
        {children}
      </CandidateJobContext.Provider>
    </div>
  );
}

export default CandidateJobProvider;
