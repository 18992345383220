import React, { useEffect, useState } from "react";
import { VARIABLES } from "../../../Constant";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";

const ProfessionalDetails = () => {
  const [professionalDetails, setProfessionalDetails] = useState([]);
  console.log(professionalDetails);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  console.log(location.state.editpost);

  const resume_id = location.state.editpost?.candidate_resume?.id;
  console.log("resume_id", resume_id);
  const user_id = location.state.editpost?.user?.id;
  console.log("user_id", user_id);

  const getProfessionalDetails = async () => {
    const url =
      VARIABLES.url + `/api/professionaldetails/?candidate_job=${resume_id}`;
    console.log(url);
    try {
      setIsLoading(true);
      const res = await axios.get(url);
      console.log("res", res.data);
      setProfessionalDetails(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log("error:", error);
    }
  };
  useEffect(() => {
    getProfessionalDetails();
  }, []);
  return (
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-body">
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h4 className="nk-block-title">
                  Candidate's Professional Details
                </h4>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div>Candidate's professional details loading...</div>
          ) : (
            <div>
              {professionalDetails == 0 ? (
                <div>No professional details found</div>
              ) : (
                <div class="nk-block">
                  {professionalDetails?.map((prof, index) => (
                    <div class="card" key={index}>
                      <div class="card-aside-wrap">
                        <div class="card-content">
                          <div class="card-inner">
                            <div class="nk-block">
                              <div class="profile-ud-list">
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Profile Name
                                    </span>
                                    <span class="profile-ud-value">
                                      {prof?.profile_name || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Organization Name
                                    </span>
                                    <span class="profile-ud-value">
                                      {prof?.organization_name || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Start Date
                                    </span>
                                    <span class="profile-ud-value">
                                      {prof?.start_date || "-"}
                                    </span>
                                  </div>
                                </div>

                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      End Date
                                    </span>
                                    <span class="profile-ud-value">
                                      {prof?.end_date || "-"}
                                    </span>
                                  </div>
                                </div>
                                <div class="profile-ud-item">
                                  <div class="profile-ud wider">
                                    <span class="profile-ud-label">
                                      Previously Worked
                                    </span>
                                    <span class="profile-ud-value">
                                      {prof?.previously_worked || "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {/* <!-- .profile-ud-list --> */}
                            </div>
                            {/* <!-- .nk-block --> */}
                            <div class="nk-divider divider md"></div>
                            <div class="nk-block">
                              <div class="nk-block-head nk-block-head-sm nk-block-between">
                                <h5 class="title">Job Responsibilities</h5>
                              </div>
                              {/* <!-- .nk-block-head --> */}
                              <div class="bq-note">
                                <div class="bq-note-item">
                                  <div class="bq-note-text">
                                    <p>{prof?.job_responsibilities || "-"} </p>
                                  </div>
                                </div>
                                {/* <!-- .bq-note-item --> */}
                              </div>
                              {/* <!-- .bq-note --> */}
                            </div>
                            <div class="nk-block">
                              <div class="nk-block-head nk-block-head-sm nk-block-between">
                                <h5 class="title">Comments</h5>
                              </div>
                              {/* <!-- .nk-block-head --> */}
                              <div class="bq-note">
                                <div class="bq-note-item">
                                  <div class="bq-note-text">
                                    <p>{prof?.comments || "-"} </p>
                                  </div>
                                </div>
                                {/* <!-- .bq-note-item --> */}
                              </div>
                              {/* <!-- .bq-note --> */}
                            </div>
                            {/* <!-- .nk-block --> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfessionalDetails;
