import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VARIABLES } from "../../../Constant";
import secureLocalStorage from "react-secure-storage";
import { AssignmentContext } from "../../../Context/AssignmentContext";

const Assignment = () => {
  const { assignment, getAssignmentData } = useContext(AssignmentContext);
  console.log(assignment);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const userID = secureLocalStorage.getItem("userID");
  console.log(userID);
  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");
  console.log(candidateJobID);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    let user = new FormData();
    user.append("upload_assignment", data.assignment_document);
    user.append("updated_by_id", userID);

    const url = VARIABLES.url + `/api/assignment/${candidateJobID}/`;
    console.log(url);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: url,
      data: user,
    };

    console.log(JSON.stringify(data));
    setSubmitLoading(true);
    axios
      .request(config)
      .then((response) => {
        setSubmitLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Succesfully submitted assignment",
        });
      })
      .catch((error) => {
        setSubmitLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to submit assignment",
        });
      });
  };

  useEffect(() => {
    getAssignmentData();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-history-item">
        <div className="nk-history-symbol">
          <div className="nk-history-symbol-dot"></div>
        </div>
        <div className="nk-history-content">
          <h5>Step 2</h5>
        </div>
      </div>
      <div className="nk-history-item">
        <div className="nk-history-content">
          <div className="card">
            <div className="card-inner">
              <h5 className="fs-14px fw-bold">Assignment</h5>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row g-4">
                  <div class="profile-ud-list">
                    <div class="profile-ud-item">
                      <div class="profile-ud wider">
                        <span
                          class="profile-ud-label"
                          style={{ width: "145px" }}
                        >
                          Link of Assignment :
                        </span>
                        <span class="profile-ud-value align-start">
                          <a
                            href={
                              assignment?.choose_assignment
                                ?.assignment_document || "-"
                            }
                            target="_blank"
                            style={{ textAlign: "left" }}
                          >
                            {" "}
                            {assignment?.choose_assignment
                              ?.assignment_document || "-"}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Upload Document{" "}
                        <span className="fs-8 text-danger">*</span>
                      </label>
                      <Controller
                        name="assignment_document"
                        control={control}
                        rules={{ required: true }}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            className="form-control"
                            id="file-form-assignment_document"
                            type="file"
                            onChange={(e) => field.onChange(e.target.files[0])}
                          />
                        )}
                      />
                      {errors.assignment_document && (
                        <span className="fs-8 text-danger">
                          Document is required
                        </span>
                      )}
                    </div>
                  </div>
                  <div class="profile-ud-list">
                    <div class="profile-ud-item">
                      <div class="profile-ud wider">
                        <span class="profile-ud-label">Status: </span>
                        <span
                          class="profile-ud-value fw-bold text-start"
                          style={{
                            color:
                              assignment?.selection_status === "Reject"
                                ? "red"
                                : assignment?.selection_status === "Accept"
                                ? "green"
                                : "black",
                          }}
                        >
                          {assignment?.selection_status || "-"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group">
                      {submitLoading ? (
                        <button className="btn text-dark" disabled>
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          id="submit-button-createassignment"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Assignment;
