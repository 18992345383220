import React, { useContext, useEffect } from "react";
import { CandidateJobContext } from "../../../Context/CandidateJobContext";

const MDApproval = () => {
  const { candidateJob, getCandidateJobData } = useContext(CandidateJobContext);

  useEffect(() => {
    getCandidateJobData();
  }, []);

  return (
    <>
      <div className="nk-history-item">
        <div className="nk-history-symbol">
          <div className="nk-history-symbol-dot"></div>
        </div>
        <div className="nk-history-content">
          <h5>Step 4</h5>
        </div>
      </div>
      <div className="nk-history-item">
        <div className="nk-history-content">
          <div className="card">
            <div className="card-body">
              <h5 className="fs-14px fw-bold">MD Approval</h5>

              <div class="profile-ud-list">
                <div class="profile-ud-item">
                  <div class="profile-ud wider">
                    <span class="profile-ud-label">Status: </span>
                    <span
                      class="profile-ud-value fw-bold text-start"
                      style={{
                        color:
                          candidateJob?.md_approval === "Reject"
                            ? "red"
                            : candidateJob?.md_approval === "Accept"
                            ? "green"
                            : "black",
                      }}
                    >
                      {candidateJob?.md_approval || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MDApproval;
