import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const AssignmentContext = createContext(null);

function AssignmentProvider({ children }) {
  const [assignment, setAssignment] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState([]);

  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");
  console.log(candidateJobID);

  const getAssignmentData = async () => {
    setDashboardLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/assignment/?candidate_job=${candidateJobID}`
      );
      setAssignment(res.data.data);
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <AssignmentContext.Provider
        value={{ assignment, dashboardLoading, getAssignmentData }}
      >
        {children}
      </AssignmentContext.Provider>
    </div>
  );
}

export default AssignmentProvider;
