import React from 'react'

const Header = () => {
  return (
    <div className="nk-header">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <a href="https://career.fluidscapes.in/" target="_blank" className="logo-link">
            <img className="logo-light logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo" />
            <img className="logo-dark logo-img logo-img-xl" src="./assets/images/fsc_animated_logo_black.gif" srcSet="./assets/images/fsc_animated_logo_black.gif" alt="logo-dark" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-lg-auto">
              <li className="nav-item"> <a className="nav-link nav-link-icon" href="https://career.fluidscapes.in/" target="_blank" > <i className="fas fa-home"></i> <span className="nav-link-inner--text d-lg-none">Home</span> </a> </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header;