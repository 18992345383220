import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";

export const StepperCandidate = createContext(null);

function StepperCandidateProvider({ children }) {
  const [CandidateDetails, setCandidateDetails] = useState([]);
  console.log(CandidateDetails);

  const [isLoading, setIsLoading] = useState(true);
  const [CandidateEntryCount, setCandidateEntryCount] = useState(null);
  const [candidatePageCount, setCandidatePageCount] = useState(0);
  const [candidateEntries, setCandidateEntries] = useState(5);

  //TELEPHONIC DATA
  const [telephonicData, setTelephonicData] = useState([]);
  const [isTelLoading, setIsTelLoading] = useState(true);

  //SHF DATA
  const [SHFData, setSHFData] = useState([]);
  const [isSHFLoading, setIsSHFLoading] = useState(true);

  const getCandidateDetailsData = async (page) => {
    const url =
      VARIABLES.url +
      `/api/candidateresume/?entries=${candidateEntries}&page=${page}`;
    console.log(url);
    try {
      setIsLoading(true);
      const res = await axios.get(url);
      setCandidateDetails(res.data.data);
      const total = res.data.count;
      setCandidateEntryCount(total);
      setCandidatePageCount(Math.ceil(total / candidateEntries));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleCandidatePageClick = async (data) => {
    console.log(data.selected);

    let page = data.selected + 1;

    const commentsFormServer = await getCandidateDetailsData(page);
  };

  const getTelephonicData = async (requisition_id, candidate_job_id) => {
    const url =
      VARIABLES.url +
      `/api/hr-telephonic/?requisition_id=${requisition_id}&candidate_job_id=${candidate_job_id}`;
    console.log(url);
    try {
      setIsTelLoading(true);
      const res = await axios.get(url);
      setTelephonicData(res.data.data);
      console.log(res.data.data);
      setIsTelLoading(false);
    } catch (error) {
      console.log(error);
      setIsTelLoading(false);
    }
  };

  const getSHFData = async (requisition_id, candidate_job_id) => {
    const url =
      VARIABLES.url +
      `/api/smarthireform/?requisition_id=${requisition_id}&candidate_job_id=${candidate_job_id}`;
    console.log(url);
    try {
      setIsSHFLoading(true);
      const res = await axios.get(url);
      setSHFData(res.data.data);
      setIsSHFLoading(false);
    } catch (error) {
      console.log(error);
      setIsSHFLoading(false);
    }
  };

  return (
    <div>
      <StepperCandidate.Provider
        value={{
          CandidateDetails,
          getCandidateDetailsData,
          candidateEntries,
          setCandidateEntries,
          isLoading,
          handleCandidatePageClick,
          CandidateEntryCount,
          candidatePageCount,

          isTelLoading,
          telephonicData,
          getTelephonicData,

          isSHFLoading,
          SHFData,
          getSHFData,
        }}
      >
        {children}
      </StepperCandidate.Provider>
    </div>
  );
}

export default StepperCandidateProvider;
