import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const SendOfferContext = createContext(null);

function SendOfferContextProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);

  const candidate_job_id = secureLocalStorage.getItem("RWFcandidate_job_id");
  console.log(candidate_job_id);

  const [sendOffer, setSendOffer] = useState([]);

  const [candidateStatus, setCandidateStatus] = useState([]);
  console.log(candidateStatus);
  const [isCandidateJobOfferDataLoading, setIsCandidateJobOfferDataLoading] =
    useState(false);

  const getCandidateJobOfferData = async (candidate_job_id, access) => {
    const url =
      VARIABLES.url + `/api/candidatejob/?candidate_job=${candidate_job_id}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    setIsCandidateJobOfferDataLoading(true);
    try {
      const res = await axios(config);
      setCandidateStatus(res.data.data);
      setIsCandidateJobOfferDataLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsCandidateJobOfferDataLoading(true);
      } else {
        setIsCandidateJobOfferDataLoading(false);
      }
    }
  };

  const getSendOfferData = async (candidate_job_id, access) => {
    const url =
      VARIABLES.url + `/api/sendoffer/?candidate_job_id=${candidate_job_id}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      setSendOffer(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 403) {
        RefreshToken();
      }
    }
  };

  const value = {
    sendOffer,
    candidateStatus,
    isCandidateJobOfferDataLoading,
    getSendOfferData,
    getCandidateJobOfferData,
  };
  return (
    <div>
      <SendOfferContext.Provider value={value}>
        {children}
      </SendOfferContext.Provider>
    </div>
  );
}

export default SendOfferContextProvider;
