import React, { useState, useEffect } from "react";
import HRInterview from "./HRInterview";
import Assignment from "./Assignment";
import MettleTest from "./MettleTest";
import MDApproval from "./MDApproval";
import OfferAttach from "./OfferAttach";

const Workflow = () => {
  const [submitMessage, setSubmitMessage] = useState(null);
  console.log(submitMessage);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);
  return (
    <>
      <div className="nk-block">
        <div className="nk-history">
          <HRInterview setSubmitMessage={setSubmitMessage} />
          <Assignment />
          <MettleTest />
          <MDApproval />
          <OfferAttach />
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Workflow;
