import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constant";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../../Context/DashboardContext";

const AdditionalInformation = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const { getPendingData, getAppliedData } = useContext(DashboardContext);

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      is_draft: false,
    },
  });
  const userID = secureLocalStorage.getItem("userID");
  const resume_id = secureLocalStorage.getItem("resume_id");

  const onSubmit = (data) => {
    console.log(data);
    var candidate_info = JSON.stringify({
      job_opening: props.JobID,
      user_id: userID,
      notice_period: data.notice_period,
      current_previous_company: data.current_previous_company,
      expected_salary: data.expected_salary,
      experience: data.experience,
      is_draft: false,
      updated_by_id: userID,
    });

    console.log(candidate_info);

    const url = VARIABLES.url + "/api/candidatejob/" + resume_id + "/";
    console.log(url);
    let config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: candidate_info,
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "You have succesfully updated your additional details",
        });
        getPendingData();
        getAppliedData();
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to update additional details",
        });
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Notice Period<span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("notice_period", { required: true })}
                    defaultValue=""
                    className="form-control"
                    id="number-forms-notice_period"
                    type="number"
                    placeholder="Enter Notice Period"
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.notice_period && (
                    <span className="fs-8 text-danger">
                      Notice Period is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Current/Previous Company
                    <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("current_previous_company", {
                      required: true,
                    })}
                    defaultValue=""
                    className="form-control"
                    id="text-forms-current_previous_company"
                    type="text"
                    placeholder="Enter Your Current/Previous Company"
                  />
                  {errors.current_previous_company && (
                    <span className="fs-8 text-danger">
                      Current/Previous Company is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Expected Salary <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("expected_salary", { required: true })}
                    defaultValue=""
                    className="form-control"
                    id="number-forms-expected_salary"
                    type="number"
                    placeholder="Enter your Expected Salary"
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.expected_salary && (
                    <span className="fs-8 text-danger">
                      Expected Salary is required
                    </span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label">
                    Experience (in years)
                    <span className="fs-8 text-danger">*</span>
                  </label>
                  <input
                    {...register("experience", {
                      required: true,
                      pattern: { value: /^\d+$/ },
                    })}
                    defaultValue=""
                    className="form-control"
                    id="number-forms-experience"
                    type="number"
                    placeholder="Enter Experience here..."
                    onWheel={(e) => e.target.blur()}
                  />
                  {errors.experience &&
                    errors.experience.type === "required" && (
                      <span className="fs-8 text-danger">
                        Experience is required
                      </span>
                    )}
                  {errors.experience &&
                    errors.experience.type === "pattern" && (
                      <span className="fs-8 text-danger">
                        Experience cannot be negative or in decimal
                      </span>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-between p-4 mt-4">
              <button
                type="button"
                onClick={props.handlePrev}
                className="btn btn-primary mr-2"
                id="button-button-previous"
              >
                Previous
              </button>
              {isLoading ? (
                <button className="btn text-dark" disabled>
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="submit-button-additionalinformation"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AdditionalInformation;
