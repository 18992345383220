import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";

export const MDApprovalContext = createContext(null);

function MDApprovalProvider({ children }) {
  const [assignment, setAssignment] = useState([]);
  const [isAssignmentDataLoading, setIsAssignmentDataLoading] = useState([]);
  const [isCandidateExamLoading, setIsCandidateExamLoading] = useState([]);
  const [candidateExam, setCandidateExam] = useState([]);
  console.log(candidateExam);

  const getAssignmentData = async (candidate_job_id) => {
    setIsAssignmentDataLoading(true);
    const url =
      VARIABLES.url + `/api/assignment/?candidate_job_id=${candidate_job_id}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setAssignment(res.data.data);
      setIsAssignmentDataLoading(false);
    } catch (error) {
      setIsAssignmentDataLoading(false);
      console.log(error);
    }
  };

  const getCandidateExamData = async (candidate_job_id) => {
    setIsCandidateExamLoading(true);
    const url =
      VARIABLES.url +
      `/api/candidateexam/?candidate_job_id=${candidate_job_id}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setCandidateExam(res.data.data);
      setIsCandidateExamLoading(false);
    } catch (error) {
      setIsCandidateExamLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <MDApprovalContext.Provider
        value={{
          assignment,
          isAssignmentDataLoading,
          candidateExam,
          isCandidateExamLoading,
          getCandidateExamData,
          getAssignmentData,
        }}
      >
        {children}
      </MDApprovalContext.Provider>
    </div>
  );
}

export default MDApprovalProvider;
