import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const CandidateExamContext = createContext(null);

function CandidateExamProvider({ children }) {
  const [candidateExam, setCandidateExam] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState([]);

  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");
  console.log(candidateJobID);

  const getCandidateExamData = async () => {
    setDashboardLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/candidateexam/?candidate_job=${candidateJobID}`
      ); //CHECK API
      setCandidateExam(res.data.data);
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <CandidateExamContext.Provider
        value={{ candidateExam, dashboardLoading, getCandidateExamData }}
      >
        {children}
      </CandidateExamContext.Provider>
    </div>
  );
}

export default CandidateExamProvider;
