import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../../Constant";
import { OfferContext } from "../../../Context/OfferContext";

const OfferAttach = () => {
  const { offer, getOfferData } = useContext(OfferContext);
  const [submitMessage, setSubmitMessage] = useState(null);
  const userID = secureLocalStorage.getItem("userID");
  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");

  const handleStatus = (data) => {
    console.log(data);
    var user = JSON.stringify({
      offer_acceptance: data,
      updated_by_id: userID,
    });

    var config = {
      method: "patch",
      url: VARIABLES.url + `/api/sendoffer/${candidateJobID}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: user,
    };

    console.log(JSON.stringify(data));
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSubmitMessage({
          type: "success",
          icon: "check",
          message: "Offer sent successfully ",
        });
      })
      .catch((error) => {
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to send offer",
        });
      });
  };

  useEffect(() => {
    getOfferData();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div className="nk-history-item">
        <div className="nk-history-symbol">
          <div className="nk-history-symbol-dot"></div>
        </div>
        <div className="nk-history-content">
          <h5>Step 5</h5>
        </div>
      </div>
      <div className="nk-history-item">
        <div className="nk-history-content">
          <div className="card">
            <div className="card-body">
              <h5 className="fs-14px fw-bold">Offer Attach</h5>
              <div className="row g-4">
                <div class="profile-ud-list">
                  <div class="profile-ud-item">
                    <div class="profile-ud wider">
                      <span class="profile-ud-label" style={{ width: "145px" }}>
                        Link of Assignment :
                      </span>
                      <span class="profile-ud-value align-start">
                        <a
                          href={offer?.offer_attachment || "-"}
                          target="_blank"
                          style={{ textAlign: "left" }}
                        >
                          {" "}
                          {offer?.offer_attachment || "-"}
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-success my-2 me-2"
                onClick={() => handleStatus(1)}
              >
                Yes
              </button>
              <button
                className="btn btn-danger my-2"
                onClick={() => handleStatus(0)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {submitMessage && (
          <div className="example-alerts position-fixed bottom-0 end-0 p-3">
            <div className="gy-4">
              <div className="example-alert">
                <div
                  className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                >
                  <em
                    className={`icon ni ni-${submitMessage.icon}-circle`}
                  ></em>{" "}
                  <strong>{submitMessage.message}</strong>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OfferAttach;
