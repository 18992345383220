import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const OfferContext = createContext(null);

function OfferContextProvider({ children }) {
  const [offer, setOffer] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState([]);
  const candidateJobID = secureLocalStorage.getItem("CWFcandidate_job_id");
  console.log(candidateJobID);

  const getOfferData = async () => {
    setDashboardLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/sendoffer/?candidate_job=${candidateJobID}`
      );
      setOffer(res.data.data);
      setDashboardLoading(false);
    } catch (error) {
      setDashboardLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <OfferContext.Provider value={{ offer, dashboardLoading, getOfferData }}>
        {children}
      </OfferContext.Provider>
    </div>
  );
}

export default OfferContextProvider;
