import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const DashboardContext = createContext(null);

function DashboardProvider({ children }) {
  const [pendingData, setPendingData] = useState([]);
  const [appliedData, setAppliedData] = useState([]);
  const [pendingDataLoading, setPendingDataLoading] = useState([]);
  console.log("pendingDataLoading", pendingDataLoading);
  const [appliedDataLoading, setAppliedDataLoading] = useState([]);
  console.log("appliedDataLoading", appliedDataLoading);
  console.log(pendingData);
  console.log(appliedData);

  const [appliedEntryCount, setAppliedEntryCount] = useState(0);
  const [pendingEntryCount, setPendingEntryCount] = useState(0);
  const [pendingPageCount, setPendingPageCount] = useState(0);
  console.log(pendingPageCount);
  const [appliedPageCount, setAppliedPageCount] = useState(0);
  console.log(appliedPageCount);
  const [pendingEntries, setPendingEntries] = useState(5);
  const [appliedEntries, setAppliedEntries] = useState(5);

  // const [userID, setUserID] = useState(null);
  // console.log(userID);

  const getPendingData = async (page, userID) => {
    setPendingDataLoading(true);
    const url = `/api/candidatejob/?entries=${pendingEntries}&page=${page}&is_draft=True&user=${userID}`;
    console.log(url);
    try {
      const res = await axios.get(VARIABLES.url + url);
      setPendingData(res.data);
      setPendingDataLoading(false);
      const total = res.data.count;
      setPendingEntryCount(total);
      setPendingPageCount(Math.ceil(total / pendingEntries));
    } catch (error) {
      setPendingDataLoading(false);
      console.log(error);
    }
  };

  const handlePendingDataPageClick = async (data, userID) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await getPendingData(currentPage, userID);
  };

  const getAppliedData = async (page, userID) => {
    setAppliedDataLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api/candidatejob/?entries=${appliedEntries}&page=${page}&is_draft=False&user=${userID}`
      );
      setAppliedData(res.data);
      setAppliedDataLoading(false);
      const total = res.data.count;
      setAppliedEntryCount(total);
      setAppliedPageCount(Math.ceil(total / appliedEntries));
    } catch (error) {
      setAppliedDataLoading(false);
      console.log(error);
    }
  };

  const handleAppliedDataPageClick = async (data, userID) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await getAppliedData(currentPage, userID);
  };

  // const getUserID = () => {
  //   console.log("getting userid");
  //   const storedUserID = secureLocalStorage.getItem("userID");
  //   if (storedUserID !== null) {
  //     console.log("if block");
  //     setUserID(storedUserID);
  //   } else {
  //     console.log("elseblock");
  //     setUserID(storedUserID);
  //     setTimeout(getUserID, 1000);
  //   }
  // };

  return (
    <div>
      <DashboardContext.Provider
        value={{
          pendingData,
          pendingDataLoading,
          appliedDataLoading,
          appliedData,
          pendingEntries,
          pendingPageCount,
          appliedPageCount,
          pendingEntryCount,
          appliedEntryCount,
          setAppliedEntries,
          setPendingEntries,
          handlePendingDataPageClick,
          handleAppliedDataPageClick,
          getPendingData,
          getAppliedData,

          // getUserID,
        }}
      >
        {children}
      </DashboardContext.Provider>
    </div>
  );
}

export default DashboardProvider;
